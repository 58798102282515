import type { TextProps } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { getPrettyPrice } from 'shared-utils'

type PriceProps = TextProps & {
  price: number | string
  currency?: string
}

export const Price = ({ price, currency, ...props }: PriceProps) => {
  const prettyPrice = useMemo(() => getPrettyPrice(price, currency), [price])

  return <Text {...props}>{prettyPrice}</Text>
}
