import type { FC, ReactNode } from 'react'
import NextLink from 'next/link'
import type { LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import { Link as ChakraLink } from '@chakra-ui/react'

interface LinkProps extends ChakraLinkProps {
  children: ReactNode | ReactNode[]
}

export const Link: FC<LinkProps> = ({ children, ...props }) => {
  return (
    <ChakraLink as={NextLink} display="flex" {...props}>
      {children}
    </ChakraLink>
  )
}

export default Link
