export const getPrettyPrice = (
  price?: number | string | null,
  currency = 'SEK',
  locales = 'sv-se'
): string | null | undefined => {
  let normalizedPrice: string | number | null | undefined =
    typeof price === 'string' ? parseFloat(price) : price
  let maximumFractionDigits = 2

  if (Number.isNaN(normalizedPrice)) {
    normalizedPrice = price
  } else if (Math.floor(normalizedPrice as number) === normalizedPrice) {
    normalizedPrice = Math.floor(normalizedPrice)
    maximumFractionDigits = 0
  }

  return typeof normalizedPrice === 'number'
    ? new Intl.NumberFormat(locales, { style: 'currency', currency, maximumFractionDigits }).format(
        normalizedPrice
      )
    : normalizedPrice
}
