'use client'
import type { FC, ReactNode } from 'react'
import {
  Box,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
  type TabsProps as ChakraTabsProps,
  Text,
  VStack
} from '@chakra-ui/react'
import { ScrollDragContainer } from './ScrollDragContainer'

interface TabsProps extends Partial<ChakraTabsProps> {
  title?: string
  tabs: {
    id: string
    title: string
  }[]
  components: ReactNode[]
  isLoading?: boolean
  isLoadingComponent?: ReactNode
}

export const Tabs: FC<TabsProps> = ({
  isLoading,
  isLoadingComponent,
  components,
  title,
  tabs,
  ...props
}) => {
  return (
    <VStack w="full">
      {title ? <Heading variant="secondary">{title}</Heading> : null}
      <ChakraTabs colorScheme="primary" w="full" {...props}>
        <TabList>
          <Box overflow="hidden" w="100vw">
            <ScrollDragContainer pl={6} py={4}>
              <HStack
                _after={{
                  content: '""',
                  minW: 2,
                  display: 'block',
                  height: 1
                }}
                alignItems="stretch"
                className="item-container"
                gap={2}
                w="100vw">
                {tabs.map((item) => (
                  <Tab
                    _selected={{
                      bg: 'background.surface',
                      color: 'text.default',
                      fontWeight: 'bold',
                      borderColor: 'text.default'
                    }}
                    alignItems="center"
                    border="1px solid"
                    borderColor="text.secondary"
                    flex={1}
                    justifyContent="center"
                    key={item.id}
                    p={0}
                    px={4}
                    py={1.5}
                    rounded="full"
                    textAlign="center">
                    <Text fontSize="xs" w="full" whiteSpace="nowrap">
                      {item.title}
                    </Text>
                  </Tab>
                ))}
              </HStack>
            </ScrollDragContainer>
          </Box>
        </TabList>
        <TabPanels py={2}>
          {!isLoading
            ? components.map((item, index) => (
                <TabPanel key={index} p={0}>
                  {item}
                </TabPanel>
              ))
            : isLoadingComponent || <Text>Loading...</Text>}
        </TabPanels>
      </ChakraTabs>
    </VStack>
  )
}

export default Tabs
