import type { ReactNode } from 'react'
import { useMemo } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

export type Ratio = '16:9' | '4:3' | '3:2' | '8:5' | '33:10' | '1:1'

export type AspectRatioProps<T, K extends keyof T> = Pick<T, K> &
  BoxProps & {
    children: ReactNode | ReactNode[]
    breakpoints: {
      xs?: Ratio
      base: Ratio
      sm?: Ratio
      md?: Ratio
      lg?: Ratio
    }
  }

function AspectRatio<T, K extends keyof T>({
  breakpoints,
  children,
  ...props
}: AspectRatioProps<T, K>) {
  const pb = useMemo(() => {
    const dict: Record<Ratio, string> = {
      '16:9': '56.25%',
      '4:3': '75%',
      '3:2': '66.66%',
      '8:5': '62.5%',
      '33:10': '30.3%',
      '1:1': '100%'
    }
    const o = Object.create(breakpoints)
    for (const k in breakpoints) {
      const key = breakpoints[k as 'base' | 'sm' | 'md']
      if (key) {
        o[k] = dict[key]
      }
    }
    return o
  }, [breakpoints])

  return (
    <Box position="relative" w="full" {...{ pb }} {...props}>
      <Box bottom={0} className="children-container" left={0} position="absolute" right={0} top={0}>
        {children}
      </Box>
    </Box>
  )
}

export default AspectRatio
