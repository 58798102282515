'use client'
import type { SheetRef } from 'react-modal-sheet'
import Sheet from 'react-modal-sheet'
import type { SheetProps } from 'react-modal-sheet/src/types'
import type { StackProps } from '@chakra-ui/react'
import { Box, HStack } from '@chakra-ui/react'
import type { Dispatch, FC, MutableRefObject, ReactNode, SetStateAction } from 'react'
import { createContext, useEffect, useRef, useState } from 'react'
import { uuidv4 } from 'shared-utils'
import { bottomSheetScrollService } from './bottomSheetScrollService'

export interface BottomSheetProps extends StackProps {
  target: ReactNode
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  sheetProps?: Partial<SheetProps>
}

export type BottomSheetContextType = {
  ref: MutableRefObject<SheetRef | undefined>
  setDisableDrag: Dispatch<SetStateAction<boolean>>
}
export const BottomSheetContext = createContext<BottomSheetContextType | null>(null)

export const BottomSheet: FC<BottomSheetProps> = ({
  target,
  children,
  onClose,
  isOpen,
  sheetProps: { onOpenStart, onOpenEnd, onCloseEnd, ...sheetProps } = {},
  ...props
}) => {
  const ref = useRef<SheetRef>()
  const [disableDrag, setDisableDrag] = useState(false)
  const sheetId = useRef(uuidv4())

  useEffect(() => {
    const id = sheetId.current
    bottomSheetScrollService.mount(id)

    return () => {
      bottomSheetScrollService.unmount(id)
    }
  }, [])

  return (
    <>
      <HStack
        align="center"
        cursor="pointer"
        flex={1}
        justify="center"
        transition="all 0.75s cubic-bezier(.33,1,.68,1) .3s"
        {...props}>
        {target}
      </HStack>

      <Sheet
        disableDrag={disableDrag}
        disableScrollLocking
        initialSnap={0}
        isOpen={isOpen}
        layoutScroll
        onCloseEnd={() => {
          onCloseEnd?.()

          bottomSheetScrollService.close(sheetId.current)
        }}
        onOpenEnd={() => {
          onOpenEnd?.()

          bottomSheetScrollService.open(sheetId.current)
        }}
        onOpenStart={() => {
          onOpenStart?.()

          bottomSheetScrollService.openStart(sheetId.current)
        }}
        rootId="root"
        snapPoints={[0.6]}
        {...{ onClose, ref }}
        {...sheetProps}>
        <Sheet.Container
          style={{
            borderTopLeftRadius: 18,
            borderTopRightRadius: 18,
            backgroundColor: 'var(--chakra-colors-background-default)'
          }}>
          <Sheet.Header />
          <Sheet.Content style={{ paddingBottom: 24 }}>
            <Sheet.Scroller draggableAt="both">
              <BottomSheetContext.Provider value={{ ref, setDisableDrag }}>
                {children}
              </BottomSheetContext.Provider>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          layoutScroll={false}
          onTap={(e) => {
            e.stopPropagation()
            onClose()
          }}>
          <Box
            _dark={{
              bg: 'whiteAlpha.300'
            }}
            backdropFilter="blur(2px)"
            bg="blackAlpha.500"
            h="full"
            w="full"
          />
        </Sheet.Backdrop>
      </Sheet>
    </>
  )
}
