'use client'

import type { FC, ReactNode } from 'react'
import { motion } from 'framer-motion'

interface JellyProps {
  isDisabled?: boolean
  children: ReactNode
}

const Jelly: FC<JellyProps> = ({ children, isDisabled }) => {
  return (
    <motion.div
      transition={{ type: 'spring', stiffness: 500 }}
      whileTap={isDisabled ? {} : { scale: 0.95 }}>
      {children}
    </motion.div>
  )
}

export default Jelly
