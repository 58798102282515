'use client'
import { ScrollContainer } from 'react-indiana-drag-scroll'
import { chakra } from '@chakra-ui/react'

import 'react-indiana-drag-scroll/dist/style.css'

const ScrollContainerChakra = chakra(ScrollContainer)

export const ScrollDragContainer = ({ ...props }) => {
  return <ScrollContainerChakra cursor="unset" {...props} />
}
