class BottomSheetScrollService {
  private dict: Record<string, { isMounted?: boolean; isOpen?: boolean; isOpenStart?: boolean }> =
    {}
  private scrollY = 0

  get isOpenedSheets() {
    return Object.values(this.dict).some(({ isOpen }) => isOpen)
  }

  mount(id: string) {
    this.dict[id] = {
      isMounted: true
    }
  }

  unmount(id: string) {
    if (this.dict[id].isOpenStart || this.dict[id].isOpen) {
      this.close(id)
    }
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- delete by id
    delete this.dict[id]
  }

  openStart(id: string) {
    if (this.dict[id]) {
      this.dict[id] = {
        ...this.dict[id],
        isOpenStart: true
      }
    }
  }

  open(id: string) {
    if (this.dict[id]) {
      this.dict[id] = {
        ...this.dict[id],
        isOpen: true
      }
    }

    this.blockScroll()
  }

  close(id: string) {
    if (this.dict[id]) {
      this.dict[id] = {
        ...this.dict[id],
        isOpen: false
      }

      if (!this.isOpenedSheets) {
        this.unblockScroll()
      }
    }
  }

  blockScroll = () => {
    if (typeof document === 'undefined') {
      return
    }

    this.scrollY = window.scrollY

    document.documentElement.style.overflow = 'hidden'
    document.body.style.overflow = 'hidden'
    document.body.style.top = `-${this.scrollY}px`
  }

  unblockScroll = () => {
    if (typeof document === 'undefined') {
      return
    }

    document.documentElement.style.overflow = ''
    document.body.style.overflow = ''
    document.body.style.top = ''
    window.scrollTo(0, this.scrollY)
  }
}

export const bottomSheetScrollService = new BottomSheetScrollService()
