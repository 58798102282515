import { withNaming } from '@bem-react/classname'

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const isEmpty = (value: any) => {
  return (
    // eslint-disable-next-line eqeqeq -- From standard.js: Always use === - but obj == null is allowed to check null || undefined
    value == null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export function truncateString(str: string, limit: number) {
  if (str.length > limit) {
    return `${str.slice(0, limit)}...`
  }
  return str
}

export const createCn = withNaming({ e: '__', m: '_', v: '-' })

export const scrollToTop = () => {
  if (typeof window === 'undefined') return

  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export const stdCanonicalFactory = <T extends { asPath: string }>(
  router: T,
  server: string | undefined
) => {
  // https://github.com/vercel/next.js/issues/35345
  const path = router.asPath.replace(/^\/index/, '/')
  return (server + path).split('?')[0]
}

export const isNullable = (value: any): boolean => value === null || typeof value === 'undefined'

export const isUndefined = (value: any): boolean => typeof value === 'undefined'

export const isDateWeekend = (date: Date) => {
  const day = date.getDay()
  // 6 - Saturday, 0 - Sunday
  return day === 6 || day === 0
}

export const commaSeparator = (x: string | number) => {
  if (typeof x === 'number') {
    // const nc = Math.ceil(x)
    const nc = x
    return Number(Math.ceil(Number(parseFloat(String(nc)).toFixed(2)))).toLocaleString('en', {
      minimumFractionDigits: Number.isInteger(nc) ? 0 : 0
    })
  }
  return x
}

export function nFormatter(num: number, digits: number) {
  return Math.abs(num) > 999
    ? `${(Math.sign(num) * (Math.abs(num) / 1000)).toFixed(digits)}k`
    : Math.sign(num) * Math.abs(num)
}

export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getWeekDay = (date: Date) => {
  const weekday = date.getDay()
  switch (weekday) {
    case 0:
      return 7
    default:
      return weekday
  }
}

export const getMillisUntil = (futureDate: Date) => {
  return futureDate.getTime() - new Date().getTime()
}

export const getFutureDate = (delay: number) => {
  const diff = new Date().getTime() + delay
  return new Date(diff)
}

export const addLabelToStringNum = (input: number | string, label: string) => {
  let text

  if (typeof input === 'number') {
    text = input.toString()
  } else {
    text = input
  }

  if (text !== '0') {
    text = text.replace(/^0+/, '')
  }

  text = text.replace(/[^$0-9\\.]/g, '').replace(/\B(?=(?:\d{3})+(?!\d))/g, ' ')

  if (text === '') {
    return text
  }
  return text.replace(/$/gm, label)
}

export const onlyNumbers = (input: number | string) => {
  let text

  if (typeof input === 'number') {
    text = input.toString()
  } else {
    text = input
  }

  text = text.replace(/[^$0-9\\.]/g, '')

  if (text === '') {
    return text
  }
  return text
}
