export const getReadableWeekday = (weekday: number, isShortName = false): string => {
  return (isShortName ? dayOfTheWeekShort : dayOfTheWeek)[weekday as DayOfTheWeek]
}

export const dayOfTheWeekShort = {
  1: 'M',
  2: 'Tu',
  3: 'W',
  4: 'Th',
  5: 'F',
  6: 'Sa',
  7: 'Sun'
}
export const dayOfTheWeek: Record<DayOfTheWeek, string> = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
}

export type DayOfTheWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7
