import type { FC, ReactNode } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { HStack, Input } from '@chakra-ui/react'

interface InputPlaceholderProps extends StackProps {
  children: ReactNode | ReactNode[]
}

export const InputPlaceholder: FC<InputPlaceholderProps> = ({ children, ...props }) => {
  return (
    <Input
      as={HStack}
      bg="background.default"
      borderWidth="1px"
      justify="space-between"
      px={4}
      rounded="md"
      w="full"
      {...props}>
      {children}
    </Input>
  )
}

export default InputPlaceholder
