import { useMemo } from 'react'
import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
  HiOutlineChevronLeft,
  HiOutlineChevronRight
} from 'react-icons/hi'
import type { IconBaseProps } from 'react-icons/lib/cjs/iconBase'
import { Flex, HStack, Text } from '@chakra-ui/react'
import { useUIDatePickerStyles } from './context'
import { UIDatePickerIconButton } from './UIDatePickerIconButton'

const cn = 'grand-date-picker__header'

export const UIDatePickerHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  increaseYear,
  decreaseYear
}: ReactDatePickerCustomHeaderProps) => {
  const [month, year] = useMemo(() => {
    return [date.toLocaleString('default', { month: 'long' }), date.getFullYear()]
  }, [date])

  const styles = useUIDatePickerStyles()
  const headerControlIconStyles = styles.headerControlIcon as IconBaseProps

  return (
    <Flex
      alignItems="center"
      className={cn}
      justifyContent="space-between"
      paddingInline="1"
      sx={styles.header}
      w="100%">
      <HStack className={`${cn}-col ${cn}-col_prev`}>
        <UIDatePickerIconButton
          aria-label="previous year"
          className={`${cn}-control ${cn}-control_prev ${cn}-control_year`}
          isDisabled={prevYearButtonDisabled}
          onClick={decreaseYear}>
          <HiOutlineChevronDoubleLeft {...headerControlIconStyles} />
        </UIDatePickerIconButton>

        <UIDatePickerIconButton
          aria-label="previous month"
          className={`${cn}-control ${cn}-control_prev ${cn}-control_month`}
          isDisabled={prevMonthButtonDisabled}
          onClick={decreaseMonth}>
          <HiOutlineChevronLeft {...headerControlIconStyles} />
        </UIDatePickerIconButton>
      </HStack>

      <HStack className={`${cn}-col ${cn}-col_month-and-year`} sx={styles.headerMonthAndYear}>
        <Text className={`${cn}-month`}>{month}</Text>

        <Text className={`${cn}-year`}>{year}</Text>
      </HStack>

      <HStack className={`${cn}-col ${cn}-col_next`}>
        <UIDatePickerIconButton
          aria-label="next month"
          className={`${cn}-control ${cn}-control_next ${cn}-control_month`}
          isDisabled={nextMonthButtonDisabled}
          onClick={increaseMonth}>
          <HiOutlineChevronRight {...headerControlIconStyles} />
        </UIDatePickerIconButton>

        <UIDatePickerIconButton
          aria-label="next year"
          className={`${cn}-control ${cn}-control_next ${cn}-control_year`}
          isDisabled={nextYearButtonDisabled}
          onClick={increaseYear}>
          <HiOutlineChevronDoubleRight {...headerControlIconStyles} />
        </UIDatePickerIconButton>
      </HStack>
    </Flex>
  )
}
