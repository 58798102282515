'use client'

import { HStack, IconButton, Text, useMultiStyleConfig } from '@chakra-ui/react'
import { FaMinus, FaPlus } from 'react-icons/fa6'
import { useCallback, useState } from 'react'
import { motion } from 'framer-motion'
import type { ThemingProps } from '@chakra-ui/system'
import Jelly from '../animated/Jelly'

type CounterProps = ThemingProps<'button'> & {
  initialValue?: number
  onChange?: (value: number) => void
  min?: number
  max?: number
}

export const Counter = ({
  initialValue,
  min = 0,
  max = Infinity,
  onChange,
  size,
  variant,
  colorScheme
}: CounterProps) => {
  const [count, setCount] = useState(initialValue ?? min)
  const themeProps = {
    size,
    variant,
    colorScheme
  }

  const chakraStyles = useMultiStyleConfig('Counter', themeProps)

  const decrease = useCallback(() => {
    if (count <= min) {
      return
    }

    const newCount = count - 1

    onChange?.(newCount)

    setCount(newCount)
  }, [count, min, onChange])

  const increase = useCallback(() => {
    if (typeof max !== 'undefined' && count >= max) {
      return
    }

    const newCount = count + 1

    onChange?.(newCount)

    setCount(newCount)
  }, [count, max, onChange])

  return (
    <HStack sx={chakraStyles.wrapper}>
      <Jelly isDisabled={count <= min}>
        <IconButton
          aria-label="Decrease"
          icon={<FaMinus />}
          isDisabled={count <= min}
          onClick={decrease}
          rounded="50%"
          sx={chakraStyles.button}
          {...themeProps}
        />
      </Jelly>

      <motion.div
        animate={{ scale: [null, 1.4, 1] }}
        exit={{ scale: 0 }}
        initial={{ scale: 1 }}
        key={count}
        transition={{ duration: 0.3 }}>
        <Text fontWeight="semibold" minWidth="1.6em" sx={chakraStyles.text} textAlign="center">
          {count}
        </Text>
      </motion.div>

      <Jelly isDisabled={count >= max}>
        <IconButton
          aria-label="Increase"
          icon={<FaPlus />}
          isDisabled={count >= max}
          onClick={increase}
          rounded="50%"
          sx={chakraStyles.button}
          {...themeProps}
        />
      </Jelly>
    </HStack>
  )
}
