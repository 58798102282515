import type { ForwardedRef, MutableRefObject } from 'react'
import Select from 'react-select'
import type SelectComponentType from 'react-select/dist/declarations/src/Select'
import type { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'
import type { StylesConfig } from 'react-select/dist/declarations/src/styles'
import { forwardRef, useColorMode } from '@chakra-ui/react'

export type UISelectSimpleOption<Value> = {
  label: string
  value: Value
}
/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint -- need it */
export type GrandSelectElement<
  Option extends unknown = unknown,
  IsMulti extends boolean = false
> = SelectComponentType<Option, IsMulti>
export type GrandSelectRef<
  Option extends unknown = unknown,
  IsMulti extends boolean = false
> = MutableRefObject<GrandSelectElement<Option, IsMulti>>

export type GrandSelectProps<
  Option extends unknown = unknown,
  IsMulti extends boolean = false
> = StateManagerProps<Option, IsMulti>

const _UISelect = <Option extends unknown = unknown, IsMulti extends boolean = false>(
  { styles = {}, ...props }: GrandSelectProps<Option, IsMulti>,
  ref: ForwardedRef<SelectComponentType<Option, IsMulti>>
) => {
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  const { control, menu, multiValue, option, input, singleValue, ...restStyles } =
    styles as StylesConfig<Option, IsMulti>

  return (
    <Select<Option, IsMulti>
      ref={ref}
      styles={{
        menu: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: 'var(--chakra-colors-background-default)',
          ...(isDark
            ? {
                boxShadow: '0 0 0 1px hsla(0, 0%, 100%, 0.2),0 4px 11px hsla(0, 0%, 100%, 0.15)'
              }
            : {}),
          ...menu?.(baseStyles, state)
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: 'var(--chakra-colors-background-default)',
          ...(isDark
            ? {
                borderColor: 'hsl(0, 0%, 50%)'
              }
            : {}),
          ...control?.(baseStyles, state)
        }),
        input: (baseStyles, state) => ({
          ...baseStyles,
          color: 'var(--chakra-colors-text-default)',
          ...input?.(baseStyles, state)
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          color: 'var(--chakra-colors-text-default)',
          ...singleValue?.(baseStyles, state)
        }),

        option: (baseStyles, state) => {
          return {
            ...baseStyles,
            ...(state.isSelected ? {} : { backgroundColor: 'transparent' }),
            ...option?.(baseStyles, state)
          }
        },
        multiValue: (baseStyles) => ({
          ...baseStyles,
          ...(isDark
            ? {
                backgroundColor: 'hsl(0, 0%, 60%)'
              }
            : {})
        }),
        multiValueLabel: (baseStyles, state) => ({
          ...baseStyles,
          ...(isDark
            ? {
                color: 'hsl(0, 0%, 90%)'
              }
            : {}),
          ...multiValue?.(baseStyles, state)
        }),
        ...restStyles
      }}
      {...props}
    />
  )
}

export const UISelect = forwardRef(_UISelect) as <
  Option extends unknown = unknown,
  IsMulti extends boolean = false
>(
  props: GrandSelectProps<Option, IsMulti> & {
    ref?: ForwardedRef<SelectComponentType<Option, IsMulti>>
  }
) => ReturnType<typeof _UISelect>
