import type { BoxProps, ButtonProps, StackProps } from '@chakra-ui/react'
import { Box, Button, forwardRef, VStack } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

type ExpandableTextProps = StackProps & {
  textProps?: BoxProps
  buttonProps?: ButtonProps
}

export const ExpandableText = forwardRef<ExpandableTextProps, 'div'>(
  ({ children, noOfLines, textProps, buttonProps, ...rest }, ref) => {
    const [expandedCount, setExpandedCount] = useState<StackProps['noOfLines']>(noOfLines)
    const [isClicked, setIsClicked] = useState(false)
    const [isTextClamped, setIsTextClamped] = useState(false)

    const checkIsClamped = useCallback(
      (element: HTMLDivElement | null) => {
        setIsTextClamped(
          (element?.scrollHeight as number) > (element?.clientHeight as number) || isClicked
        )
      },
      [isClicked]
    )

    const handleToggle = () => {
      setIsClicked(true)
      setExpandedCount(expandedCount ? undefined : noOfLines)
    }

    return (
      <VStack alignItems="flex-start" ref={ref} {...rest}>
        <Box
          noOfLines={expandedCount}
          ref={(element) => {
            checkIsClamped(element)
          }}
          {...textProps}>
          {children}
        </Box>

        <Button
          display={isTextClamped ? 'block' : 'none'}
          onClick={handleToggle}
          size="sm"
          variant="link"
          {...buttonProps}>
          {!expandedCount ? 'Read less' : 'Read more'}
        </Button>
      </VStack>
    )
  }
)
