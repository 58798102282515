'use client'

import { useCallback, useState } from 'react'
import { useUpdateEffect } from '@chakra-ui/react'
import type { WithOptional } from 'shared-utils'
import { useUIGlobalsContext } from '../lib/ui-globals/hooks'
import type { GrandImageProps } from './GrandImage'
import { GrandImage } from './GrandImage'

type AppImageProps = WithOptional<GrandImageProps, 'alt'>

export const AppImage = ({ src, alt, ...props }: AppImageProps) => {
  const { defaultImgSrc } = useUIGlobalsContext()
  const [imgSrc, setImgSrc] = useState(src || defaultImgSrc)

  useUpdateEffect(() => {
    setImgSrc(src || defaultImgSrc)
  }, [src])

  const onError = useCallback(() => {
    setImgSrc(defaultImgSrc)
  }, [defaultImgSrc])

  return <GrandImage alt={alt || ''} onError={onError} src={imgSrc} {...props} />
}
