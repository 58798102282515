'use client'

import type { StackProps } from '@chakra-ui/react'
import { Box, HStack, Text, useMultiStyleConfig } from '@chakra-ui/react'
import { nFormatter } from 'shared-utils'
import type { ReactNode } from 'react'
import { useRef } from 'react'
import type { ThemingProps } from '@chakra-ui/system'
import { omitThemingProps } from '@chakra-ui/system'

type PriceProps = StackProps & {
  avgRating: number
  nRatings: number
  renderReviews?: (reviewsAmount: number | string) => ReactNode
} & ThemingProps<'Rating'>

export const Rating = ({ avgRating, nRatings, renderReviews, ...props }: PriceProps) => {
  const chakraStyles = useMultiStyleConfig('Rating', props)
  const sanitizedProps = omitThemingProps(props)
  const rating = useRef(nFormatter(nRatings, 1))

  return (
    <HStack align="center" sx={chakraStyles.wrapper} {...sanitizedProps}>
      <HStack sx={chakraStyles.bulletsWrapper}>
        {[...Array(5).keys()]
          .map((e) => e + 1)
          .map((e) => (
            <Box
              __css={chakraStyles.bullet}
              {...(avgRating >= e
                ? {}
                : (() => {
                    const d = e - avgRating

                    if (d >= 1) {
                      return { bg: 'none' }
                    }

                    if (Math.round(1 - d) > 0) {
                      return {}
                    }

                    return {
                      position: 'relative',
                      overflow: 'hidden',
                      bg: 'none',
                      _before: {
                        content: '""',
                        position: 'absolute',
                        left: 'calc(-50% + 1px)',
                        top: 0,
                        ...chakraStyles.bullet
                      }
                    }
                  })())}
              key={e}
              rounded="50%"
            />
          ))}
      </HStack>

      {renderReviews ? (
        renderReviews(rating.current)
      ) : (
        <Text sx={chakraStyles.reviews}>{rating.current || ''}</Text>
      )}
    </HStack>
  )
}
