'use client'

import type { FC, ReactNode } from 'react'
import type { MotionProps } from 'framer-motion'
import { motion } from 'framer-motion'

interface LoadAnimationProps extends MotionProps {
  children: ReactNode
}

export const LoadAnimation: FC<LoadAnimationProps> = ({ children, ...props }) => {
  return (
    <motion.div
      initial="hidden"
      transition={{
        duration: 0.75,
        ease: 'anticipate',
        type: 'spring',
        bounce: 0.4
      }}
      variants={{
        visible: { opacity: 1, scale: 1, x: 0 },
        hidden: { opacity: 0, scale: 0.9, x: 10 }
      }}
      viewport={{ once: true }}
      whileInView="visible"
      {...props}>
      {children}
    </motion.div>
  )
}

export default LoadAnimation
