import { useContext } from 'react'
import { BottomSheetContext } from './BottomSheet'

export const useBottomSheet = () => {
  const ctx = useContext(BottomSheetContext)

  if (!ctx) {
    throw Error('useBottomSheet should only be used inside a BottomSheet component')
  }

  return ctx
}
