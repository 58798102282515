import type { ChakraComponent, HTMLChakraProps } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/react'
import type { ImageProps as NextImageProps } from 'next/image'
import NextImage from 'next/image'

// NextImageProps without deprecated ones
type FreshNextImageProps = Omit<
  NextImageProps,
  'objectFit' | 'layout' | 'objectPosition' | 'lazyBoundary' | 'lazyRoot'
>

export type GrandImageProps = FreshNextImageProps &
  Omit<HTMLChakraProps<'img'>, keyof FreshNextImageProps>
export const GrandImage = chakra(NextImage, {
  shouldForwardProp: (prop: string) =>
    [
      'src',
      'sizes',
      'alt',
      'width',
      'height',
      'fill',
      'loader',
      'quality',
      'priority',
      'loading',
      'placeholder',
      'blurDataURL',
      'unoptimized',
      'onLoadingComplete'
    ].includes(prop)
}) as ChakraComponent<'img', FreshNextImageProps>
