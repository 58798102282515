import type { FC } from 'react'
import type { RangeSliderProps } from '@chakra-ui/react'
import {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack
} from '@chakra-ui/react'

type IRangeFilterItemProps = RangeSliderProps

export const RangeFilterItem: FC<IRangeFilterItemProps> = ({ ...props }) => {
  return (
    <RangeSlider flexGrow={1} focusThumbOnChange={false} step={25} {...props}>
      <RangeSliderTrack bg="background.surface">
        <RangeSliderFilledTrack bg="background.surface" />
      </RangeSliderTrack>
      <RangeSliderThumb bg="primary.300" boxSize={6} index={0} />
      <RangeSliderThumb bg="primary.300" boxSize={6} index={1} />
    </RangeSlider>
  )
}
