import type { UIDatePickerProps } from './UIDatePickerBase'
import { UIDatePickerBase } from './UIDatePickerBase'

export type UIDatePickerAndTimeProps = UIDatePickerProps<false>

export const UIDatePickerAndTime = ({
  showTimeSelect = true,
  timeIntervals = 15,
  ...props
}: UIDatePickerAndTimeProps) => {
  return (
    <UIDatePickerBase<false>
      showTimeSelect={showTimeSelect}
      timeIntervals={timeIntervals}
      {...props}
    />
  )
}
