import type { ForwardedRef } from 'react'
import type { ReactDatePickerProps } from 'react-datepicker'
import ReactDatePicker from 'react-datepicker'
import type { BoxProps } from '@chakra-ui/react'
import { Box, forwardRef, Input, useMultiStyleConfig } from '@chakra-ui/react'
import { type ThemingProps } from '@chakra-ui/styled-system'
import { UIDatePickerStylesProvider } from './context'
import { UIDatePickerHeader } from './UIDatePickerHeader'

import 'react-datepicker/dist/react-datepicker.css'

export type UIDatePickerElement<WithRange extends boolean | undefined = undefined> =
  ReactDatePicker<never, WithRange>

export type UIDatePickerProps<WithRange extends boolean | undefined = undefined> =
  ReactDatePickerProps<never, WithRange> & ThemingProps & Omit<BoxProps, 'onChange'>

const _UIDatePickerBase = <WithRange extends boolean | undefined = undefined>(
  {
    adjustDateOnChange = true,
    allowSameDay,
    ariaDescribedBy,
    ariaInvalid,
    ariaLabelClose,
    ariaLabelledBy,
    ariaRequired,
    autoComplete,
    autoFocus,
    calendarClassName,
    calendarContainer,
    calendarStartDay = 1,
    children,
    chooseDayAriaLabelPrefix,
    className,
    clearButtonClassName,
    clearButtonTitle,
    closeOnScroll,
    customInput = <Input />,
    customInputRef,
    customTimeInput,
    dateFormat,
    dateFormatCalendar,
    dayClassName,
    weekDayClassName,
    monthClassName,
    timeClassName,
    disabledDayAriaLabelPrefix,
    disabled,
    disabledKeyboardNavigation,
    dropdownMode,
    endDate,
    excludeDates,
    excludeDateIntervals,
    excludeTimes,
    filterDate,
    filterTime,
    fixedHeight = true,
    forceShowMonthNavigation,
    formatWeekDay = (formattedDate) => String(formattedDate)[0],
    formatWeekNumber,
    highlightDates,
    id,
    includeDates,
    includeDateIntervals,
    includeTimes,
    injectTimes,
    inline,
    focusSelectedMonth,
    isClearable,
    locale,
    maxDate,
    maxTime,
    minDate,
    minTime,
    monthsShown,
    name,
    nextMonthAriaLabel,
    nextMonthButtonLabel,
    nextYearAriaLabel,
    nextYearButtonLabel,
    onBlur,
    onCalendarClose,
    onCalendarOpen,
    onChange,
    onChangeRaw,
    onClickOutside,
    onDayMouseEnter,
    onFocus,
    onInputClick,
    onInputError,
    onKeyDown,
    onMonthChange,
    onMonthMouseLeave,
    onSelect,
    onWeekSelect,
    onYearChange,
    open,
    openToDate,
    peekNextMonth,
    placeholderText,
    popperClassName,
    popperContainer,
    popperModifiers,
    popperPlacement,
    popperProps,
    preventOpenOnFocus,
    previousMonthAriaLabel,
    previousMonthButtonLabel,
    previousYearAriaLabel,
    previousYearButtonLabel,
    readOnly,
    renderCustomHeader = (headerProps) => <UIDatePickerHeader {...headerProps} />,
    renderDayContents,
    required,
    scrollableMonthYearDropdown,
    scrollableYearDropdown,
    selected,
    selectsEnd,
    selectsStart,
    selectsRange,
    shouldCloseOnSelect,
    showDisabledMonthNavigation,
    showFullMonthYearPicker,
    showMonthDropdown,
    showMonthYearDropdown,
    showMonthYearPicker,
    showPopperArrow,
    showPreviousMonths,
    showQuarterYearPicker,
    showTimeInput,
    showTimeSelect,
    showTimeSelectOnly,
    showTwoColumnMonthYearPicker,
    showFourColumnMonthYearPicker,
    showWeekNumbers,
    showYearDropdown = false,
    showYearPicker,
    startDate,
    startOpen,
    strictParsing,
    tabIndex,
    timeCaption,
    timeFormat,
    timeInputLabel,
    timeIntervals,
    title,
    todayButton,
    useShortMonthInDropdown,
    useWeekdaysShort = true,
    weekAriaLabelPrefix,
    monthAriaLabelPrefix,
    value,
    weekLabel,
    withPortal,
    portalId,
    portalHost,
    wrapperClassName,
    yearDropdownItemNumber,
    excludeScrollbar,
    enableTabLoop,
    yearItemNumber,

    colorScheme,
    size,
    variant,
    orientation,
    styleConfig,

    ...boxProps
  }: UIDatePickerProps<WithRange>,
  ref: ForwardedRef<UIDatePickerElement<WithRange>>
) => {
  const themingProps = {
    colorScheme,
    size,
    variant,
    orientation,
    styleConfig
  }

  const styles = useMultiStyleConfig('DatePicker', themingProps)

  return (
    <UIDatePickerStylesProvider value={styles}>
      <Box __css={styles.wrapper} className="ui-date-picker" {...boxProps}>
        <ReactDatePicker
          ref={ref}
          {...{
            adjustDateOnChange,
            allowSameDay,
            ariaDescribedBy,
            ariaInvalid,
            ariaLabelClose,
            ariaLabelledBy,
            ariaRequired,
            autoComplete,
            autoFocus,
            calendarClassName,
            calendarContainer,
            calendarStartDay,
            children,
            chooseDayAriaLabelPrefix,
            className,
            clearButtonClassName,
            clearButtonTitle,
            closeOnScroll,
            customInput,
            customInputRef,
            customTimeInput,
            dateFormat,
            dateFormatCalendar,
            dayClassName,
            weekDayClassName,
            monthClassName,
            timeClassName,
            disabledDayAriaLabelPrefix,
            disabled,
            disabledKeyboardNavigation,
            dropdownMode,
            endDate,
            excludeDates,
            excludeDateIntervals,
            excludeTimes,
            filterDate,
            filterTime,
            fixedHeight,
            forceShowMonthNavigation,
            formatWeekDay,
            formatWeekNumber,
            highlightDates,
            id,
            includeDates,
            includeDateIntervals,
            includeTimes,
            injectTimes,
            inline,
            focusSelectedMonth,
            isClearable,
            locale,
            maxDate,
            maxTime,
            minDate,
            minTime,
            monthsShown,
            name,
            nextMonthAriaLabel,
            nextMonthButtonLabel,
            nextYearAriaLabel,
            nextYearButtonLabel,
            onBlur,
            onCalendarClose,
            onCalendarOpen,
            onChange,
            onChangeRaw,
            onClickOutside,
            onDayMouseEnter,
            onFocus,
            onInputClick,
            onInputError,
            onKeyDown,
            onMonthChange,
            onMonthMouseLeave,
            onSelect,
            onWeekSelect,
            onYearChange,
            open,
            openToDate,
            peekNextMonth,
            placeholderText,
            popperClassName,
            popperContainer,
            popperModifiers,
            popperPlacement,
            popperProps,
            preventOpenOnFocus,
            previousMonthAriaLabel,
            previousMonthButtonLabel,
            previousYearAriaLabel,
            previousYearButtonLabel,
            readOnly,
            renderCustomHeader,
            renderDayContents,
            required,
            scrollableMonthYearDropdown,
            scrollableYearDropdown,
            selected,
            selectsEnd,
            selectsStart,
            selectsRange,
            shouldCloseOnSelect,
            showDisabledMonthNavigation,
            showFullMonthYearPicker,
            showMonthDropdown,
            showMonthYearDropdown,
            showMonthYearPicker,
            showPopperArrow,
            showPreviousMonths,
            showQuarterYearPicker,
            showTimeInput,
            showTimeSelect,
            showTimeSelectOnly,
            showTwoColumnMonthYearPicker,
            showFourColumnMonthYearPicker,
            showWeekNumbers,
            showYearDropdown,
            showYearPicker,
            startDate,
            startOpen,
            strictParsing,
            tabIndex,
            timeCaption,
            timeFormat,
            timeInputLabel,
            timeIntervals,
            title,
            todayButton,
            useShortMonthInDropdown,
            useWeekdaysShort,
            weekAriaLabelPrefix,
            monthAriaLabelPrefix,
            value,
            weekLabel,
            withPortal,
            portalId,
            portalHost,
            wrapperClassName,
            yearDropdownItemNumber,
            excludeScrollbar,
            enableTabLoop,
            yearItemNumber
          }}
        />
      </Box>
    </UIDatePickerStylesProvider>
  )
}

export const UIDatePickerBase = forwardRef(_UIDatePickerBase) as <
  WithRange extends boolean | undefined = undefined
>(
  props: UIDatePickerProps<WithRange> & { ref?: ForwardedRef<UIDatePickerElement<WithRange>> }
) => ReturnType<typeof _UIDatePickerBase>
