import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import { useUIDatePickerStyles } from './context'

export const UIDatePickerIconButton = ({ children, ...props }: IconButtonProps) => {
  const styles = useUIDatePickerStyles().headerControl as IconButtonProps

  return (
    <IconButton {...styles} {...props}>
      {children}
    </IconButton>
  )
}
