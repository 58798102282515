'use client'

import type { ReactNode } from 'react'
import { useEffect, useCallback, useState } from 'react'
import type { CenterProps } from '@chakra-ui/react'
import { Spinner, Center } from '@chakra-ui/react'
import { LuUser } from 'react-icons/lu'
import { isUndefined } from 'shared-utils'
import type { GrandImageProps } from './GrandImage'
import { GrandImage } from './GrandImage'

export type GrandAvatarProps = CenterProps & {
  imageProps: Partial<GrandImageProps>
  fallback?: ReactNode
  maxLoadAttempts?: number
  isLoading?: boolean
}

export const GrandAvatar = ({
  imageProps: { src, alt, ...imageProps },
  fallback,
  children,
  maxLoadAttempts = 0,
  isLoading: _isLoading,
  ...props
}: GrandAvatarProps) => {
  const [isError, setIsError] = useState(false)
  const [currentAttempt, setCurrentAttempt] = useState(0)
  const [isAttempting, setIsAttempting] = useState(false)

  const isLoading = _isLoading || isAttempting

  useEffect(() => {
    setCurrentAttempt(0)
    setIsError(false)
    setIsAttempting(false)
  }, [src])

  const onError = useCallback(() => {
    if (maxLoadAttempts < currentAttempt) {
      setIsAttempting(true)

      setTimeout(() => {
        setCurrentAttempt((prev) => prev + 1)
      }, 1000)

      return
    }

    setIsError(true)
    setIsAttempting(false)
  }, [currentAttempt, maxLoadAttempts])

  const onLoad = useCallback(() => {
    setIsAttempting(false)
  }, [])

  const renderFallback = () => (isUndefined(fallback) ? <LuUser /> : <>{fallback}</>)

  return (
    <Center
      bg="gray.300"
      color="#fff"
      h="40px"
      overflow="hidden"
      position="relative"
      rounded="50%"
      w="40px"
      {...props}>
      <>
        {!!src && !isError ? (
          <GrandImage
            alt={alt || ''}
            fill
            key={currentAttempt}
            onError={onError}
            onLoad={onLoad}
            src={src}
            {...imageProps}
          />
        ) : (
          renderFallback()
        )}

        {isLoading && (
          <Center
            backdropFilter="blur(4px)"
            bg="rgba(0,0,0,0.1)"
            h="100%"
            position="absolute"
            w="100%"
            zIndex={2}>
            <Spinner />
          </Center>
        )}

        {children}
      </>
    </Center>
  )
}
